<template>
  <div class="main">
    
    <v-container fluid class="pa-0">
      <v-row no-gutters justify="center" align="center">
        <v-col xl = "4" lg="6" md="8" sm="10">
          <OCRForm></OCRForm>
        </v-col>
      </v-row>
    </v-container>

    <!-- <WelcomeScreen></WelcomeScreen> -->
    
  </div>
</template>

<script>
  // import WelcomeScreen from "../components/home/WelcomeScreen";
  import OCRForm from "../components/home/OCR.vue"

  export default {
    name: 'Main',
    components: {OCRForm},
    data() {
      return {
      }
    }
  }
</script>

<style scoped>
  .main{
    width: 100%;
    height: 100%
  }

  .slant {
    position: relative;
    overflow: hidden;
    z-index: 0;
    -webkit-clip-path: polygon(0 0, 100% 15%, 100% 80%, 0 100%);
    clip-path: polygon(0 0, 100% 15%, 100% 80%, 0 100%);
  }
</style>