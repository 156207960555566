<template>
  <div>
    <v-form ref="form" lazy-validation>
      <div class="viewport">
        <v-card style="background-color: transparent" elevation="0">
          <v-toolbar style="background: rgb(220, 220, 220); padding: 5px;">
            <span > {{t.book_info}}</span>
          </v-toolbar>
            <v-flex xs12>
                <v-container grid-list-md text-xs-center>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-text-field 
                        v-model="bookInfo.title"
                        :label = "t.title"
                        :rules="[rules.emptyTitle]"
                        outline
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6>
                      <v-text-field 
                        v-model="bookInfo.volume"
                        :label = "t.volume"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-text-field 
                        v-model="bookInfo.author"
                        :label = "t.author"
                        :rules="[rules.emptyAuthor]"
                        outline
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6>
                      <v-text-field 
                        v-model="bookInfo.death_year"
                        :label = "t.death_year"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-text-field
                        v-model="bookInfo.publisher"
                        :label = "t.publisher"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs6>
                      <v-text-field 
                        v-model="bookInfo.researcher"
                        :label = "t.researcher"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="bookInfo.tags"
                        :label = "t.tags"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                <v-select v-model="bookInfo.language" :label="t.language" id="language" :items="languages">
                </v-select>
                </v-container>
            </v-flex>
        </v-card>
      </div>
      <div style="margin-top: 20px">
        <v-file-input
          v-model="pdf_file"
          accept="application/pdf"
          :disabled="uploading"
          :placeholder="t.select_pdf_file"
          :label="t.pdf_file"
          :rules="[rules.emptyFile]"
          required
        ></v-file-input>
      </div>
      </v-form>

      <div style="margin-top: 20px">
        <v-progress-linear
          v-show="uploading"
          v-model="uploadProgress"
          color="blue-grey"
          height="25">
          <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
        </v-progress-linear>

        <v-row style="margin-top: 20px">
          <span> {{t.terms_conditions}} </span>
        </v-row>

        <v-row align="center" justify="space-around" style="margin-top: 10px">
          <v-btn
          class="ma-2"
          :disabled="uploading"
          color="primary"
          @click="submit"
          >
          {{t.submit_ocr}}
        </v-btn>
        </v-row>
        
      </div>
    
    <div class="text-center">
      <v-snackbar v-model="showSnackbar" :timeout="60000">
        <!-- <md-icon v-show="uploadFailed" style="color: red;"> error_outlined </md-icon>
        <md-icon v-show="uploadSuccessful" style="color: green;"> check_circle_outlined </md-icon> -->
        <span> {{ message }} </span>
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
            {{t.dismiss}}
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>  
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import i18n from '../../i18n'
  import auth from '@/modules/auth';

  export default {
    name: 'OCRForm',
    data: () => ({
      bookInfo: {
        language: i18n.t('message.arabic'),
        title: null,
        volume: null,
        author: null,
        death_year: null,
        publisher: null,
        researcher: null,
        tags: null,
      },
      pdf_file: {"name": ""},
      uploadProgress: 20,
      uploading: false,
      uploadSuccessful: false,
      uploadFailed: false,
      message: '',
      showSnackbar: false,
      languages: [i18n.t('message.arabic'), i18n.t('message.english'), i18n.t('message.farsi')],
      user: null,
      rules: {
        emptyTitle: v => !!v || i18n.t("message.empty_title"),
        emptyAuthor: v => !!v || i18n.t("message.empty_author"),
        emptyFile: v => !!v.name || i18n.t("message.empty_file"),
      },
      t: {
        book_info: i18n.t("message.book_info"),
        title: i18n.t("message.title"),
        volume: i18n.t("message.volume"),
        author: i18n.t("message.author"),
        death_year: i18n.t("message.death_year"),
        publisher: i18n.t("message.publisher"),
        researcher: i18n.t("message.researcher"),
        tags: i18n.t("message.tags"),
        select_pdf_file: i18n.t("message.select_pdf_file"),
        pdf_file: i18n.t("message.pdf_file"),
        terms_conditions: i18n.t("message.terms_conditions"),
        submit_ocr: i18n.t("message.submit_ocr"),
        dismiss: i18n.t("message.dismiss"),
        book_info: i18n.t("message.book_info"),
        book_info: i18n.t("message.book_info"),
        book_info: i18n.t("message.book_info"),
        book_info: i18n.t("message.book_info"),
        book_info: i18n.t("message.book_info"),
      },
    }),
    methods: {
      clearForm () {
        this.$v.$reset()
        this.bookInfo.title = null
        this.bookInfo.author = null
        this.bookInfo.volume = null
        this.bookInfo.tags = null
        this.bookInfo.death_year = null
        this.bookInfo.publisher = null
        this.bookInfo.researcher = null
        this.message = ''
        this.showSnackbar = false
      },

      validateForm(){
        console.log(this.bookInfo)
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.submit()
        }
      },

      updateUser(){
        auth.getUserInfo()
        this.user = auth.user
      },

      submit() {
        if (this.pdf_file.size > 50 * 1024 * 1024) {
            this.message = "File too big (> 50MB)"
            this.showSnackbar = true
            return;
        }
        if (this.$refs.form.validate()) {
          this.errors = null

          this.updateUser()
          if(!this.user.accountActivated){
            this.message = i18n.t("message.ocr_response_account_not_activated")
            this.showSnackbar = true
            return
          }

          let formData = new FormData()
          formData.append('pdf_file', this.pdf_file)
          formData.append('output_type', 'pdf,txt')
          formData.append('language', this.bookInfo.language)
          formData.append('book_info', JSON.stringify(this.bookInfo))

          this.uploading = true
          this.uploadProgress = 0
          this.uploadSuccessful = false
          this.uploadFailed = false
          this.message = ''
          this.showSnackbar = false

          axios.post(process.env.VUE_APP_API_URL + '/ocr/upload', formData, {
              headers: {
                'Content-Type': "multipart/form-data; charset=utf-8;"
              },
              onUploadProgress: function( progressEvent ) {
                this.uploadProgress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
              }.bind(this)
            }
          ).then(response => {
            this.uploading = false
            if (response.status === 202){
              this.uploadSuccessful = true
            }
            else {
              this.uploadFailed = true
            }
            this.message = i18n.t("message." + response.data.message)
            this.showSnackbar = true
          }).catch(err => {
            this.uploading = false
            this.uploadFailed = true
            this.message = err
            this.showSnackbar = true
            if (err.response.status === 422) {
              this.errors = []
              _.each(err.response.data.errors, error => {
                _.each(error, e => {
                  this.errors.push(e)
                })
              })
            }
          });
        }

      },
    }

  }
</script>


<style lang="scss" scoped>

.viewport 
{
  margin-top:128px;
  border: 1px solid rgba(#000, .12);
}
</style>